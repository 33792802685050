import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//components
import { ProfileComponent } from './profile/profile.component'

const routes: Routes = [
  { path: '', redirectTo: '/profile', pathMatch: 'full' },
  { path: 'profile', component: ProfileComponent },
  {
    path: 'videos', loadChildren: () =>
      import('./videos/videos.module').then(m => m.VideosModule)
  },
  {
    path: 'links', loadChildren: () =>
      import('./links/links.module').then(m => m.LinksModule)
  },
  {
    path: '**', loadChildren: () =>
      import('./no-page-found/no-page-found.module').then(m => m.NoPageFoundModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
