<div fxLayout="row" fxLayoutAlign="space-evenly center"  fxLayoutGap="5px">
    <button mat-mini-fab color="button-color" [routerLink]="['/profile']">
      <mat-icon>
        perm_identity
      </mat-icon>
    </button>
    <button mat-mini-fab color="button-color" [routerLink]="['/videos']">
      <mat-icon>
        ondemand_video
      </mat-icon>
    </button>
    <button mat-mini-fab color="button-color" [routerLink]="['/links']">
      <mat-icon>
        link
      </mat-icon>
    </button>
</div>
