import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

    //properties
    titlePage : String = "Mi perfil";
    subtitlePage : String = "Breve información";    

  constructor() { }

  ngOnInit(): void {
  }

}
