<mat-card fxFlex.xs="100%" fxLayoutWrap class="form-container mat-elevation-z4">
    <mat-card-header>
      <div mat-card-avatar class="info-header-image"></div>
      <mat-card-title style="text-align: justify;">{{titlePage}}</mat-card-title>
      <mat-card-subtitle>{{subtitlePage}}</mat-card-subtitle>
    </mat-card-header>
   <mat-card-content class="profile-text" >
      <p>
          Technical Lead y Senior Software Developer con Especialización en Finanzas para No Financieros.
          Más de 11 años de experiencia tanto en el sector público y privado. Sumergido en el mundo FinTech,
          en donde se combina los dos sectores que más me apasionan, Tecnología y Finanzas.
        </p>
        <p>
          Cuento con una especialización en <b>Finanzas para No Financieros</b> en la Pacifico Business School, una certificación
          <a href="http://81cd1176253f3f59d435-ac22991740ab4ff17e21daf2ed577041.r77.cf1.rackcdn.com/Certificate/ScrumFundamentalsCertified-JorgeJeffreyVargasIpince-766029.pdf" target="_blank">
          Scrum Master</a> para el entorno ágil y
          <a href="https://drive.google.com/file/d/1oTOUosCpLqGYpOifPUHAu7OC8w7yCMTS/view?usp=sharing" target="_blank">
          Azure Fundamentals</a> para el mundo Cloud.
      </p>
      <p>
        Fundador del canal de YouTube<a href="https://www.youtube.com/c/kaizenforce/" target="_blank"> Kaizen Force</a>,
        donde comparto el conocimiento adquirido dentro de mi experiencia, orientado principalmente al mundo del
        desarrollo de software con tecnologías Microsoft. Todos los ejemplos los puedes encontrar en el repositorio de
        <a href="https://github.com/jvargasipince" target="_blank"> GitHub</a>
      </p>
      <p>
        Si deseas contactarme para resolver alguna duda o necesitas una consultoria independiente,
        te invito a revisar mi <a href="https://www.linkedin.com/in/jvargasipince/" target="_blank"> perfil</a>
        o envíame un <a href="mailto:jvargas.ipince@outlook.com" target="_blank"> mail</a> y estaré gustoso de poder
        atenderte.
      </p>
    </mat-card-content>
    <mat-card-content class="img-certification-path">
      <img src="../../assets/img/certifications/badge-SFC.png" alt="Scrum Fundamentals" class="img-certification">
      <img src="../../assets/img/certifications/badge-SMC.png" alt="Scrum Master" class="img-certification">
      <img src="../../assets/img/certifications/badge-AzF.png" alt="Azure Fundamentals" class="img-certification">
    </mat-card-content>
    <img src="../../assets/img/kaizenforce_Ninja.webp" alt="Kaizen Force">
  </mat-card>

